body {
  background-color: #0b1944;
}

.grid-item {
  overflow: hidden;
}

.grid-item img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.grid {
  width: 100%;
}
